import React, { useEffect, useState } from 'react'
import { useLocation, NavLink, useHistory } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { isMobile } from 'react-device-detect'
import { useTranslation } from 'react-i18next'
import {
  setInviteFriendsStatus,
  setShowCashierPopup,
  setShowChat,
  setShowNotification,
  setShowSideNav
} from 'redux-thunk/redux/slices/settings.slice'
import { getItem, setItem } from 'helpers/localstorage.helpers'
import {
  CRYPTO_NAME,
  ROUTE_PATHS,
  SHOW_SIDE_NAV,
  TOOLTIP_VARIANT
} from 'constants/index'
// import { getCasinoAllCategories } from 'redux-thunk/thunk/game.thunk'
import { openInfoToaster } from 'helpers/toaster.helpers'
import {
  NftMenuIcon,
  RankUpMenuIcon,
  FriendMenuIcon,
  PromotionMenuIcon,
  PartnerProgramMenuIcon,
  ProfileUserIcon,
  BellSolidIcon,
  ChatWhiteIconIcon
} from 'components/ui-kit/Icons/svg/index'
import { setSearchParam } from 'redux-thunk/redux/slices/sportsbook.slice'
import LiveValues from './components/LiveValues/index'
import { getCryptoCoins } from 'redux-thunk/thunk/crypto.thunk'
import { setCryptoCoin } from 'redux-thunk/redux/slices/cryptocoin.slice'
import CustomTooltip from 'components/ui-kit/Tooltip/index'
import CasinoSection from './components/CasinoSection/index'
import CryptoSection from './components/CryptoSection/index'
import './sideNavBar.scss'

function SideNavbar () {
  const { t } = useTranslation()
  const dispatch = useDispatch()
  const { pathname, search } = useLocation()
  const { showSideNav } = useSelector((state) => state?.settings)
  const history = useHistory()
  const { loginData } = useSelector((state) => state.auth)
  const { newAnnouncements } = useSelector(state => state.announcement)
  const [windowWidth, setWindowWidth] = useState(window.innerWidth)
  const [windowHeight, setWindowHeight] = useState(window.innerHeight)
  const [showCasinoOptions, setShowCasinoOptions] = useState(false)
  const [showCryptoOptions, setShowCryptoOptions] = useState(false)

  useEffect(() => {
    setShowCasinoOptions(!!loginData?.accessToken)
  }, [loginData?.accessToken])

  const setWindowDimensions = () => {
    setWindowWidth(window.innerWidth)
    setWindowHeight(window.innerHeight)
  }

  const sidenavExpand = () => {
    setItem(SHOW_SIDE_NAV, !showSideNav)
    dispatch(setShowSideNav(!showSideNav))
    document
      .getElementsByClassName('sidebar-wrap')[0]
      ?.classList.toggle('sidebar-close')
    document
      .getElementsByClassName('page-container')[0]
      ?.classList.toggle('sidebar-close')
    document
      .getElementsByClassName('header')[0]
      ?.classList.toggle('sidebar-close')
  }

  useEffect(() => {
    if (isMobile) {
      dispatch(setShowSideNav(false))
    }
  }, [pathname])

  useEffect(() => {
    if (pathname === ROUTE_PATHS.SPORTS_BOOK) {
      dispatch(setSearchParam(search?.slice(12)))
    }
  }, [search])

  useEffect(() => {
    if (!showSideNav) {
      setShowCasinoOptions(false)
      setShowCryptoOptions(false)
    }
  }, [showSideNav])

  useEffect(() => {
    window.addEventListener('resize', setWindowDimensions)
    return () => {
      window.removeEventListener('resize', setWindowDimensions)
    }
  }, [])

  useEffect(() => {
    if (windowWidth <= 768) {
      setItem(SHOW_SIDE_NAV, false)
      dispatch(setShowSideNav(false))
    }
  }, [windowHeight, windowWidth])

  useEffect(() => {
    const id = pathname.includes('crypto') && pathname.split('/')[2]
    id && dispatch(setCryptoCoin({ name: CRYPTO_NAME[id], id: id }))
    dispatch(getCryptoCoins())
    dispatch(setShowSideNav(getItem(SHOW_SIDE_NAV)))
    // dispatch(getCasinoAllCategories())
  }, [])

  return (
    <>
      <div className={`sidebar-wrap ${!showSideNav ? 'sidebar-close' : ''}`}>
        <div className='sidebar-content-wrap w-100'>
          {loginData?.accessToken && (
            <>
              {/* <LiveValues /> */}
              <div className='after-login-sidebar-content d-block d-xxl-none p-3'>
                <div className='d-flex'>
                  <button
                    className='btn btn-secondary w-100 text-uppercase'
                    onClick={() => dispatch(setShowCashierPopup(true))}
                  >
                    {t('cashier')}
                  </button>
                </div>
              </div>
            </>
          )}

          <div className='sidebar-menu-list'>
            <h4 className='sidevar-menu-heading'>{t('mainMenu')}</h4>
            <ul>

              <CasinoSection
                showCasinoOptions={showCasinoOptions}
                setShowCasinoOptions={setShowCasinoOptions}
              />
              <CryptoSection
                setShowCryptoOptions={setShowCryptoOptions}
                showCryptoOptions={showCryptoOptions}
                setShowCasinoOptions={setShowCasinoOptions}
                showCasinoOptions={showCasinoOptions}
              />

              <li>
                <a
                  href=''
                  onClick={(e) => {
                    e.preventDefault()
                    openInfoToaster({ message: 'Coming Soon!' })
                  }}
                >
                  <CustomTooltip
                    variant={TOOLTIP_VARIANT.SIDE_BAR}
                    message={t('NFT')}
                    showTooltip={!showSideNav}
                  >
                    <div className='menu-left d-flex align-items-center'>
                      <div className='menu-icon'>
                        <NftMenuIcon />
                      </div>
                      <p className='m-0'>{t('NFT')}</p>
                    </div>
                  </CustomTooltip>
                  <span className='menu-arrow'>
                    <i className='fa fa-caret-down' aria-hidden='true' />
                  </span>
                </a>
              </li>
            </ul>
            <h4 className='sidevar-menu-heading sidebar-bottom-title'>
              {t('earnRewards')}
            </h4>
            <ul>
              <li>
                <NavLink
                  to={loginData?.accessToken ? ROUTE_PATHS.REWARDS : ROUTE_PATHS.PRE_LOGIN_REWARDS}
                >
                  <CustomTooltip
                    variant={TOOLTIP_VARIANT.SIDE_BAR}
                    message={t('rankUp')}
                    showTooltip={!showSideNav}
                  >
                    <div className='menu-left d-flex align-items-center'>
                      <div className='menu-icon'>
                        <RankUpMenuIcon />
                      </div>
                      <p className='m-0'>{t('rankUp')}</p>
                    </div>
                  </CustomTooltip>
                </NavLink>
              </li>
              <li>
                <a
                  href=''
                  onClick={(e) => {
                    e.preventDefault()
                    if (loginData?.accessToken) {
                      dispatch(setShowCashierPopup(true))
                      dispatch(setInviteFriendsStatus(true))
                    } else {
                      history.push(ROUTE_PATHS.PRE_LOGIN_AFFILIATES)
                    }
                  }}
                >
                  <CustomTooltip
                    variant={TOOLTIP_VARIANT.SIDE_BAR}
                    message={t('inviteFriends')}
                    showTooltip={!showSideNav}
                  >
                    <div className='menu-left d-flex align-items-center'>
                      <div className='menu-icon'>
                        <FriendMenuIcon />
                      </div>
                      <p className='m-0'>{t('inviteFriends')}</p>
                    </div>
                  </CustomTooltip>
                </a>
              </li>
              <li>
                <NavLink to={ROUTE_PATHS.PROMOTIONS}>
                  <CustomTooltip
                    variant={TOOLTIP_VARIANT.SIDE_BAR}
                    message={t('accountMenuPromotions')}
                    showTooltip={!showSideNav}
                  >
                    <div className='menu-left d-flex align-items-center'>
                      <div className='menu-icon'>
                        <PromotionMenuIcon />
                      </div>
                      <p className='m-0'>{t('accountMenuPromotions')}</p>
                    </div>
                  </CustomTooltip>
                </NavLink>
              </li>
              <li>
                <a
                  href=''
                  onClick={(e) => {
                    e.preventDefault()
                    openInfoToaster({ message: 'Coming Soon!' })
                  }}
                >
                  <CustomTooltip
                    variant={TOOLTIP_VARIANT.SIDE_BAR}
                    message={t('partnerProgram')}
                    showTooltip={!showSideNav}
                  >
                    <div className='menu-left d-flex align-items-center'>
                      <div className='menu-icon'>
                        <PartnerProgramMenuIcon />
                      </div>
                      <p className='m-0'>{t('partnerProgram')}</p>
                    </div>
                  </CustomTooltip>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
      <div className='Mobile-bottom-bar d-block d-xl-none'>
        <ul className='list-inline mb-0 d-flex justify-content-between overflow-auto flex-nowrap'>
          <li className='list-inline-item'>
            <button
              type='button'
              onClick={sidenavExpand}
              className='btn btn-primary'
            >
              <i className='fa fa-bars' aria-hidden='true' />
            </button>
          </li>
          {loginData?.accessToken && (
            <>
              <li className='list-inline-item'>
                <button
                  className='btn btn-primary search-btn'
                  onClick={() => {
                    history.push(ROUTE_PATHS.PROFILE)
                    dispatch(setShowChat(false))
                  }}
                >
                  <ProfileUserIcon />
                </button>
              </li>
            </>
          )}
          <li className='list-inline-item'>
            <button
              className='btn btn-primary'
              onClick={() => {
                dispatch(setShowChat(true))
                dispatch(setShowNotification(false))
              }}
            >
              <ChatWhiteIconIcon />
            </button>
          </li>
          {loginData?.accessToken &&
            <li className='list-inline-item'>
              <button
                className='btn btn-primary'
                onClick={() => {
                  dispatch(setShowChat(false))
                  dispatch(setShowNotification(true))
                }}
              >
                {newAnnouncements &&
                  <div className='chat-notification-icon' />}
                <BellSolidIcon />
              </button>
            </li>}
        </ul>
      </div>
    </>
  )
}

export default React.memo(SideNavbar)
