import { CryptoMenuIcon } from 'components/ui-kit/Icons/svg/index'
import CustomTooltip from 'components/ui-kit/Tooltip/index'
import { ROUTE_PATHS, TOOLTIP_VARIANT } from 'constants/index'
import React from 'react'
import { NavLink, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'

import { setCryptoCoin } from 'redux-thunk/redux/slices/cryptocoin.slice'
import RollerCoasterComp from '../RollerCoasterPrice/index'
import { cryptoIcon } from 'utils/helperFunctions.utils'
import CryptoPrice from '../CryptoPrice/index'

const CryptoSection = (props) => {
  const { t } = useTranslation()
  const { setShowCryptoOptions, showCryptoOptions, setShowCasinoOptions, showCasinoOptions } = props
  const { pathname } = useLocation()
  const { cryptoCoinsList } = useSelector((state) => state.crypto)
  const { showSideNav } = useSelector((state) => state?.settings)
  const showCryptosList = showCryptoOptions && showSideNav && cryptoCoinsList.length > 0

  const dispatch = useDispatch()
  return (
    <>
      <li>
        <NavLink
          to={ROUTE_PATHS.ROLLERCOASTER}
          className={
                    pathname === ROUTE_PATHS.ROLLERCOASTER ||
                      pathname.match(`${ROUTE_PATHS.CRYPTO}`)
                      ? 'active'
                      : ''
                  }
          onClick={() => {
            // if (!loginData?.accessToken) {
            //   dispatch(setShowLoginPopup(true))
            // } else {
            dispatch(setCryptoCoin({ name: 'Roller Coaster', id: '' }))
            // }
          }}
        >
          <CustomTooltip
            variant={TOOLTIP_VARIANT.SIDE_BAR}
            message={t('crypto')}
            showTooltip={!showSideNav}
          >
            <div className='menu-left d-flex align-items-center'>
              <div className='menu-icon'>
                <CryptoMenuIcon />
              </div>
              <p className='m-0'>{t('crypto')}</p>
            </div>
          </CustomTooltip>
          <span
            className='menu-arrow'
            onClick={(e) => {
              e.preventDefault()
              e.stopPropagation()
              setShowCryptoOptions((prev) => !prev)
            }}
          >
            <i
              className={`fa fa-caret-${showCryptoOptions ? 'up' : 'down'
                        }`}
              aria-hidden='true'
            />
          </span>
        </NavLink>
        {showCryptosList && (
          <ul className='submenu-wrap crypto-cont'>
            <RollerCoasterComp />
            {/* {cryptoCoinsList?.map((item, index) => (
              <li
                key={index}
                className={`crypto-list-wrap ${pathname === `${ROUTE_PATHS.CRYPTO}/${item?.id}`
                          ? 'active'
                          : ''
                          }`}
              >
                <NavLink
                  to={`${ROUTE_PATHS.CRYPTO}/${item?.id}`}
                  onClick={(e) => {
                    // if (!loginData?.accessToken) {
                    //   dispatch(setShowLoginPopup(true))
                    // }
                    showCasinoOptions &&
                    setShowCasinoOptions(false)
                    dispatch(setCryptoCoin({ name: item?.name, id: item?.id }))
                  }}
                  className='crypto-list d-flex align-items-center justify-content-between'
                >
                  <div className='d-flex align-items-center crypto-list-left'>
                    <img src={cryptoIcon(item?.id)} alt={item?.name} className='crypto-img' />
                    <span>{item?.symbol.toUpperCase()}</span>
                  </div>
                  <CryptoPrice changeShow id={item?.id} />
                </NavLink>
              </li>
            ))} */}
          </ul>
        )}
      </li>
      {showCryptoOptions && <hr className='theme-divider' />}
    </>
  )
}

export default CryptoSection
